import ship from './ship.svg';
import './App.css';
import MatrixEffect from './Ship.js';
import logo from './piratelogo.png';

function App() {
  return (
    <>
      <div style={{position: 'absolute', top: 20, left: 20, zIndex: 1, display: 'flex'}}>
      <img src={logo} style={{height: '8vh'}} alt="logo" />
      </div>
      <div className="App" style={{display: 'flex', overflow: 'hidden', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',  zIndex: 0}}>
        
        {/* <MatrixEffect width={600} height={600} style={{}}/> */}<img src={ship} style={{height: '90vh', marginLeft: 'auto', marginRight: -200, marginTop: 'auto', marginBottom: 0}} alt="logo" />
      </div>
    </>
  );
}

export default App;
